var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('InfoBanner',{attrs:{"module_name":this.module_name}}),_c('PageTitle',{attrs:{"hide_btns":""},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Проверка гарантии ")]},proxy:true}])}),(_vm.info_message())?_c('InfoAlert',{staticClass:"mb-5",attrs:{"is_dark":""},scopedSlots:_vm._u([{key:"alert_text",fn:function(){return [_c('div',{domProps:{"innerHTML":_vm._s(_vm.info_message())}})]},proxy:true}],null,false,631939790)}):_vm._e(),_c('v-card',{class:_vm.$style.card},[_c('v-row',{staticClass:"mt-0"},[_c('v-col',{attrs:{"cols":"12","sm":"12","lg":"6"}},[_c('div',{class:[_vm.$style.filters]},[_c('Search',{class:_vm.$style.searchForm,attrs:{"label":"Серийный номер","dense":""},on:{"submit":_vm.check_warranty},model:{value:(_vm.warranty_check_number),callback:function ($$v) {_vm.warranty_check_number=$$v},expression:"warranty_check_number"}}),_c('v-btn',{attrs:{"color":"accent darken-4","depressed":"","disabled":!_vm.warranty_check_number,"loading":_vm.loading},on:{"click":_vm.check_warranty}},[_vm._v(" Проверить ")])],1)])],1),(_vm.request_sent)?_c('div',[(_vm.warranty_data.length)?[_c('Table',{attrs:{"headers":[].concat( _vm.warranty_data_cols ),"items":_vm.warranty_data,"custom-sort":_vm.prevent_sort,"loading":_vm.loading,"hide-default-footer":"","not_resizeble":true},scopedSlots:_vm._u([{key:"item.number",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.number)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.number)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.number)+" ")])],1)]}},{key:"item.nomenclature",fn:function(ref){
var item = ref.item;
return [_c('div',{class:{'d-flex align-center': !_vm.is_mobile}},[(item.nomenclature)?_c('v-tooltip',{attrs:{"color":"primary darken-3","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){$event.stopPropagation();return _vm.copy(item.nomenclature)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-content-copy")])],1)]}}],null,true)},[_c('span',[_vm._v("Копировать")])]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(item.nomenclature)+" ")])],1)]}},{key:"item.expired_date",fn:function(ref){
var item = ref.item;
return [_c('td',{staticClass:"py-2"},[_vm._v(" "+_vm._s(_vm._f("date")(item.expired_date))+" ")])]}},{key:"item.warranty_ticket",fn:function(ref){
var item = ref.item;
return [_c('tr',{staticClass:"py-2"},[_c('td',[_c('DownloadBtn',{attrs:{"src":item.file,"title":'Скачать'}})],1)])]}}],null,true)}),(_vm.extended_warranties.length)?_c('div',{staticClass:"py-4",class:_vm.$style.expand_wrapper},_vm._l((_vm.extended_warranties),function(extended_warranty){
var _obj;
return _c('div',{key:extended_warranty.id,class:[_vm.$style.expand_item_wrapper, ( _obj = {}, _obj[_vm.$style.actual_extended_warranty] = extended_warranty.actual, _obj )]},[(extended_warranty.type)?_c('span',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(extended_warranty.type)+" ")]):_vm._e(),(extended_warranty.date_start)?_c('div',[_c('span',[_vm._v("Начало гарантии: ")]),_c('span',[_vm._v(_vm._s(_vm._f("date")(extended_warranty.date_start)))])]):_vm._e(),(extended_warranty.date_end)?_c('div',[_c('span',[_vm._v("Завершение гарантии: ")]),_c('span',[_vm._v(_vm._s(_vm._f("date")(extended_warranty.date_end)))])]):_vm._e()])}),0):_vm._e()]:_c('div',{staticClass:"text-center"},[_c('h2',[_vm._v("Устройство не найдено.")]),_c('div',[_vm._v(" По заданным параметрам устройство не найдено. "),_c('br'),_vm._v(" Измените параметры поиска. ")])])],2):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }